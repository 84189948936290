import styles from './styles.module.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionContainer } from 'src/components/sectionContainer';
import { ContentfulArticleResource, Maybe } from 'src/graphql-types';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import {
	defaultRichTextOptions,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import { QueryResultArticle } from './protectedArticleDetailsTemplate';
import { ApolloError } from '@apollo/client';
import {
	SERVER_RESPONSE_NO_ACCESS,
	SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE,
	formatDate,
} from 'src/utils/common';
import { curatedTitle } from 'src/utils/curated-title';
import { LoginForMore } from 'src/components/loginComponents/loginForMore';

interface PageContext {
	pageContext: {
		article: Maybe<ContentfulArticleResource | QueryResultArticle>;
		loading?: boolean;
		error?: ApolloError;
		refetch?: (params?: any) => any;
	};
}

interface MessageProps {
	message: string;
}

const SimpleMessageContent = (props: MessageProps) => {
	return (
		<SectionContainer withBorderTop>
			<p className={styles.message}>{props.message}</p>
		</SectionContainer>
	);
};

export default class ArticleDetails extends React.Component<PageContext> {
	render() {
		const { article, loading = false, error, refetch } = this.props.pageContext;

		const notLoggedIn = error && error.message === SERVER_RESPONSE_NO_ACCESS;
		const notAuthorised = error && error.message === SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE;

		const getContent = () => {
			if (loading) {
				return <SimpleMessageContent message="Loading content, please wait" />;
			}
			if (error) {
				if (notLoggedIn) {
					return <LoginForMore onRefetch={refetch} withSectionContainer />;
				} else if (notAuthorised) {
					return <SimpleMessageContent message="This content is not available" />;
				} else {
					return (
						<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
					);
				}
			}

			if (article) {
				return (
					<SectionContainer isGrey withBorderTop>
						<h6 className={styles.articleType}>{article.articleType}</h6>
						<h4 className={styles.articleTitle}>{article.title}</h4>
						<p className={styles.publicationDate}>{formatDate(article.publicationDate)}</p>
						{article.externalArticleUrl && (
							<p className={styles.externalArticleLinkContainer}>
								Link to external article: &nbsp;
								<a
									className={styles.externalArticleLink}
									href={article.externalArticleUrl}
									target="_blank"
								>
									{article.title || article.externalArticleUrl}
								</a>
							</p>
						)}
						{getRichText(article?.content, defaultRichTextOptions)}
					</SectionContainer>
				);
			}

			return (
				<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
			);
		};

		return (
			<DefaultLayoutTemplate showLogin={notLoggedIn} onLoginSuccess={refetch}>
				<Helmet>
					<title>{curatedTitle(article?.title)}</title>
				</Helmet>
				{getContent()}
			</DefaultLayoutTemplate>
		);
	}
}
